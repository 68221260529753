<template>
    <div
        class="page"
        v-loading.fullscreen.lock="loading"
        element-loading-spinner="el-icon-loading"
        element-loading-text="加载中，请稍后......"
        element-loading-background="rgba(0, 0, 0, 0.7)"
    >
        <div class="page_header">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item>了解威有</el-breadcrumb-item>
                <el-breadcrumb-item>人才招聘</el-breadcrumb-item>
                <el-breadcrumb-item>人才战略</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="page_content">
            <el-form :model="form" label-width="120px">
                <el-form-item label="简介">
                    <el-input type="textarea" autosize resize="none" v-model.trim="form.title"></el-input>
                </el-form-item>
                <el-form-item label="图片">
                    <uploadImgMultiple :src="form.image" @addSrc="set" @removeSrc="set"> </uploadImgMultiple>
                </el-form-item>
                <!-- <el-form-item label="右侧图片">
                    <UploadImg :src="form.image" @removeSrc="form.image = ''" ref="image"></UploadImg>
                </el-form-item> -->
            </el-form>
        </div>
        <!-- <div class="page_fab">
            <button style="color: #000" @click="yes">确认</button>
            <button style="background-color: #000" @click="getData">重新加载</button>
        </div> -->
        <div class="page_fab">
            <VyIcon name="确认" scale="4" @click.native="yes"></VyIcon>
            <VyIcon name="重新加载" scale="4" @click.native="getData"></VyIcon>
        </div>
    </div>
</template>

<script>
import axios from "axios";
// import UploadImg from "@/components/customs/uploadImg.vue";
import QueryString from "qs";
import VyIcon from "@/components/customs/VyIcon.vue";
import uploadImgMultiple from "@/components/customs/uploadImgMultiple.vue";

export default {
    components: { uploadImgMultiple, VyIcon },
    data() {
        return {
            form: {},
            loading: true,
        };
    },
    created() {
        this.getData();
    },
    methods: {
        async getData() {
            let {
                data: { image, title },
            } = await axios.get("/api/recruit/strategyshow");
            this.form = { image, title };
            this.loading = false;
        },
        // 确认
        async yes() {
            this.loading = true;
            // this.form.image = this.$refs.image.url;
            let { data } = await axios.put("/api/recruit/strategyup", QueryString.stringify(this.form));
            if (data.code != 200) {
                this.$message.error(data.msg);
            } else {
                this.$message.success(data.msg);
            }
            this.getData();
        },
        set(data) {
            this.form.image = data;
        },
    },
};
</script>

<style lang="less" scoped>
.page {
    .page_headerSecondary {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .page_content {
        margin-top: 20px;
        .el-table {
            border-radius: 20px;
        }
        .btns {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
        }
    }
}
</style>
